// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik } from 'formik';

import { RODO_WYBIERZ, RODO_SPRZECZNE } from '@constants/alerts';
import { showAlert as showAlertAction } from '@actions/appActions';

// Service
import { saveAdvancedrodoConfigProgram as saveAdvancedrodoConfigProgramService } from '@services/programServices';

// Data
import { surveyQuestions } from '@views/Dashboard/Programs/ProgramConsortium/programSurveyQuestions';

// Elements
import { Grid, Radio, FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Checkbox from '@components/atoms/Checkbox/Checkbox';

import ProgramRODOKatalog from './ProgramRODOKatalog';

import ProgramRODODetails from './ProgramRODODetails';
// Styles
import { StyledButton } from './ProgramSurvey.styles';
import './ProgramSurvey.scss';

// Component
const ProgramSurvey = ({ details, saveAdvancedrodoConfigProgram, showAlert }) => {
  const [groupSelectedToEntrust, setGroupSelectedToEntrust] = useState([]);
  const [onlyShow, setOnlyShow] = useState(false);
  const newCustomItems = () => {
    const staticCustomData = {};

    surveyQuestions.map((item) => {
      staticCustomData[item.value] = [];

      if (item.radioLabels && item.radioLabels.length > 0) {
        staticCustomData[item.value] = {
          name: item.name,
          items: {},
        };

        item.radioLabels.map((it) => {
          let itVal = false;
          let itRequired = false;

          if (it.required) {
            itVal = true;
            itRequired = true;
          }

          staticCustomData[item.value].items[it.value] = {
            name: it.name,
            value: itVal,
            required: itRequired,
          };
          return true;
        });
      }

      return true;
    });

    return staticCustomData;
  };

  useEffect(() => {
    if (details.program_organizations) {
      setOnlyShow(details.onlyShow);
      const tmpGroupSelectedToEntrust = details.program_organizations.map(
        (el) =>
          details.group.selectedToEntrust.find((ste) => ste.id === el.id) || {
            id: el.id,
            customData: newCustomItems(),
            checked: false,
            beneficiary: 'Wszyscy beneficjenci z programu',
            data: 'all',
          },
      );
      setGroupSelectedToEntrust(
        tmpGroupSelectedToEntrust.map((el) =>
          el.customData == null ? { ...el, customData: newCustomItems() } : el,
        ),
      );
    }
  }, [details.program_organizations]);

  return (
    <>
      {details.program_organizations && groupSelectedToEntrust.length && (
        <Formik
          initialValues={{
            time: 0,
            groupRules: details.groupRules || {
              beneficiary: 'Wszyscy beneficjenci z programu',
              customData: newCustomItems(),
              data: 'all',
            },
            group: {
              entrustProcessingData: details.group.entrustProcessingData ? '1' : '2',
              selectedToEntrust: groupSelectedToEntrust,
            },
            organizations: details.program_organizations.map((el) => ({
              id: el.id,
              organization: el.organization,
              coAdministrator: el.coAdministrator || false,
              dataAdministrator: el.dataAdministrator || false,
              entrustProcessingData: el.entrustProcessingData ? '1' : '2',
              selectedToEntrust:
                el.selectedToEntrust ||
                details.program_organizations.map((el2) => ({
                  id: el2.id,
                  customData: newCustomItems(),
                  checked: false,
                  beneficiary: '',
                  data: '',
                })),
            })),
          }}
          // enableReinitialize
          onSubmit={(values) => {
            let submit = true;

            if (
              values.organizations.filter(
                (o) => o.dataAdministrator === false && o.coAdministrator === false,
              ).length
            ) {
              if (values.group.entrustProcessingData === '1') {
                if (values.group.selectedToEntrust.filter((el) => el.checked).length === 0) {
                  showAlert(RODO_WYBIERZ);
                  submit = false;
                }
              }
              values.organizations.map((org) => {
                if (org.dataAdministrator && org.entrustProcessingData === '1') {
                  if (org.selectedToEntrust.filter((el) => el.checked).length === 0) {
                    showAlert(RODO_WYBIERZ);
                    submit = false;
                  }
                }
                return true;
              });
            }
            if (submit) {
              if (
                values.organizations[0].selectedToEntrust[1].customData.contact.items.contactDetails
              ) {
                saveAdvancedrodoConfigProgram(details.id, JSON.stringify(values));
              }
            }
          }}
        >
          {({ values, setFieldValue, handleChange, handleSubmit }) => (
            <>
              <Grid item xs={12}>
                <hr
                  style={{
                    color: '#ccc',
                    backgroundColor: '#ccc',
                    height: 2,
                  }}
                />

                {details.advanced_rodo_preset === 'Typ2' && (
                  <>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Heading.H5>Grupa organizacji współadministrujących dane</Heading.H5>
                      </Grid>

                      {values.organizations.map((el, index) => (
                        <Grid item xs={12} key={el.id}>
                          <Checkbox
                            value={values.organizations[index].coAdministrator}
                            label={values.organizations[index].organization?.name}
                            onChange={(_, val) => {
                              if (val && values.organizations[index].dataAdministrator === val) {
                                showAlert(RODO_SPRZECZNE);
                              }
                              setFieldValue(`organizations[${index}].coAdministrator`, val);
                              if (val) {
                                setFieldValue(`organizations[${index}].dataAdministrator`, !val);
                              }
                            }}
                            name={`organizations[${index}].coAdministrator`}
                            isEditable={!onlyShow}
                            disabled={onlyShow}
                          />
                        </Grid>
                      ))}

                      {values.organizations.filter((el) => {
                        if (el.coAdministrator) {
                          return true;
                        }

                        return false;
                      }).length > 0 && (
                        <>
                          <Grid
                            item
                            xs={12}
                            style={{
                              backgroundColor: '#F3F3F3',
                              marginTop: '0.5rem',
                              marginBottom: '2.5rem',
                              padding: '1rem',
                            }}
                          >
                            {/* {console.log(values.groupRules)} */}
                            <ProgramRODOKatalog
                              groupCatalog
                              indexFromParent={0}
                              groupBeginningName="groupRules"
                              groupBeginningObject={values.groupRules}
                              details={details}
                              //      saveAdvancedrodoConfigProgram={saveAdvancedrodoConfigProgram}
                              handleChange={handleChange}
                              values={values}
                              setFieldValue={setFieldValue}
                              // handleSubmit={handleSubmit}
                              onlyShow={onlyShow}
                              currentOrganizationIndex={0}
                            />
                          </Grid>

                          {values.organizations.filter(
                            (el) => !(el.dataAdministrator || el.coAdministrator),
                          ).length ? (
                            <>
                              <Grid item xs={12}>
                                <Heading.Subtitle3>
                                  Czy grupa powierza przetwarzanie danych
                                </Heading.Subtitle3>
                                <FormControl
                                  className="radioGrupa"
                                  component="fieldset"
                                  style={{ paddingTop: '1rem' }}
                                >
                                  <RadioGroup
                                    className="radioGrupa"
                                    row
                                    name="group.entrustProcessingData"
                                    value={values.group.entrustProcessingData}
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      value="1"
                                      control={<Radio color="primary" />}
                                      label="Tak"
                                      disabled={onlyShow}
                                    />
                                    <FormControlLabel
                                      value="2"
                                      control={<Radio color="primary" />}
                                      label="Nie"
                                      disabled={onlyShow}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              {values.group.entrustProcessingData === '1' && (
                                <>
                                  <Heading.Subtitle3>
                                    Wybierz organizacje, którym dane są powierzane
                                  </Heading.Subtitle3>

                                  {values ? (
                                    <ProgramRODODetails
                                      group
                                      currentOrganizationIndex="group"
                                      details={details}
                                      availableOrganizations={values.organizations.map((el) =>
                                        !(el.dataAdministrator || el.coAdministrator) ? el : null,
                                      )}
                                      values={values}
                                      handleChange={handleChange}
                                      setFieldValue={setFieldValue}
                                      // handleSubmit={handleSubmit}
                                      onlyShow={onlyShow}
                                    />
                                  ) : null}
                                </>
                              )}
                            </>
                          ) : (
                            (onlyShow && ' ') ||
                            'Brak dostępnych organizacji do powierzenia danych (wszystkie organizacje są administratorami lub współadministratorami)'
                          )}
                        </>
                      )}
                    </Grid>

                    <hr
                      style={{
                        color: '#ccc',
                        backgroundColor: '#ccc',
                        height: 2,
                      }}
                    />
                  </>
                )}

                <Grid item xs={12}>
                  <Heading.H5>Samodzielni administratorzy</Heading.H5>
                </Grid>

                {values.organizations.map((el, index) => (
                  <Grid item xs={12} key={el.id}>
                    <Grid item xs={12}>
                      <Checkbox
                        value={values.organizations[index].dataAdministrator}
                        label={values.organizations[index].organization?.name}
                        onChange={(_, val) => {
                          if (val && values.organizations[index].coAdministrator === val) {
                            showAlert(RODO_SPRZECZNE);
                          }
                          setFieldValue(`organizations[${index}].dataAdministrator`, val);
                          if (val) {
                            setFieldValue(`organizations[${index}].coAdministrator`, !val);
                          }
                        }}
                        name={`organizations[${index}].dataAdministrator`}
                        isEditable={!onlyShow}
                        disabled={onlyShow}
                      />
                    </Grid>
                    {values.organizations[index].dataAdministrator === true && (
                      <>
                        {values.organizations.filter(
                          (org) => !(org.dataAdministrator || org.coAdministrator),
                        ).length ? (
                          <>
                            <Grid item xs={8}>
                              <Heading.Subtitle3>
                                Czy organizacja powierza przetwarzanie danych
                              </Heading.Subtitle3>
                              <FormControl
                                className="radioGrupa"
                                component="fieldset"
                                style={{ paddingTop: '1rem' }}
                              >
                                <RadioGroup
                                  className="radioGrupa"
                                  row
                                  name={`organizations[${index}].entrustProcessingData`}
                                  value={values.organizations[index].entrustProcessingData}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio color="primary" />}
                                    label="Tak"
                                    disabled={onlyShow}
                                  />
                                  <FormControlLabel
                                    value="2"
                                    control={<Radio color="primary" />}
                                    label="Nie"
                                    disabled={onlyShow}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            {values.organizations[index].entrustProcessingData === '1' && (
                              <>
                                <Heading.Subtitle3>
                                  Wybierz organizacje, którym dane są powierzane
                                </Heading.Subtitle3>
                                <ProgramRODODetails
                                  details={details}
                                  values={values}
                                  currentOrganizationIndex={index}
                                  availableOrganizations={values.organizations.map((org) =>
                                    !(org.dataAdministrator || org.coAdministrator) ? org : null,
                                  )}
                                  // saveAdvancedrodoConfigProgram={saveAdvancedrodoConfigProgram}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  //  handleSubmit={handleSubmit}
                                  onlyShow={onlyShow}
                                />

                                <Grid item xs={4}>
                                  <hr
                                    style={{
                                      color: '#ccc',
                                      backgroundColor: '#ccc',
                                      height: 2,
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                          </>
                        ) : (
                          (onlyShow && ' ') ||
                          'Brak dostępnych organizacji do powierzenia danych (wszystkie organizacje są administratorami lub współadministratorami)'
                        )}
                      </>
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {!onlyShow && (
                    <Grid style={{ paddingTop: '3rem' }} item xs={8}>
                      <StyledButton variant="outlined" onClick={handleSubmit}>
                        zatwierdź
                      </StyledButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

ProgramSurvey.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        organization_id: PropTypes.number,
        organization_name: PropTypes.string,
      }),
    ),
    program_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    group: PropTypes.shape({
      entrustProcessingData: PropTypes.bool,
      selectedToEntrust: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
        }),
      ),
    }),
    groupRules: PropTypes.shape({
      customData: PropTypes.shape({
        beneficiary_data: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
      beneficiary: PropTypes.string,
      data: PropTypes.string,
    }),
    onlyShow: PropTypes.bool,
    advanced_rodo_preset: PropTypes.string,
  }),
  showAlert: PropTypes.func,
  saveAdvancedrodoConfigProgram: PropTypes.func,
};

ProgramSurvey.defaultProps = {
  details: {
    id: null,
    name: '',
    type: null,
    organizations: [],
    program_organizations: [],
    group: null,
    onlyShow: false,
    groupRules: {
      customData: null,
      beneficiary: 'Wszyscy beneficjenci z programu',
      data: 'all',
    },
    advanced_rodo_preset: '',
  },
  showAlert: null,
  saveAdvancedrodoConfigProgram: null,
};

const mapStateToProps = ({ programReducer }) => ({
  details: programReducer.consortium,
});

const mapDispatchToProps = (dispatch) => ({
  showAlert: (data) => dispatch(showAlertAction(data)),
  saveAdvancedrodoConfigProgram: (program_id, data) =>
    dispatch(saveAdvancedrodoConfigProgramService(program_id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramSurvey);
