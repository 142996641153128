// React
import React /* , { useState, useContext } */ from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Context
// import { Context } from '@context';

// Elements
import { IconButton, Tooltip } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import {
  //  StyledMenu,
  StyledTooltip,
  StyledBadge,
  StyledAnnouncementRoundedIcon,
  // StyledNotification,
  // StyledButton,
} from './AnnouncementIcon.styles';

// Component
const AnnouncementIcon = ({ announcements }) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  // const { setWhichItem } = useContext(Context);
  // console.log(announcements);
  return (
    <>
      <Tooltip
        title={
          <StyledTooltip>
            <Heading.Body1 color="white">Ogłoszenia</Heading.Body1>
          </StyledTooltip>
        }
        // onClick={(e) => {
        //   setWhichItem('');
        //   setAnchorEl(e.currentTarget);
        // }}
        onClick={() => {
          window.location = '/ogloszenia';
        }}
        aria-label="add"
      >
        <IconButton edge="start" aria-label="menu">
          <StyledBadge badgeContent={announcements.count_unread} color="secondary">
            <StyledAnnouncementRoundedIcon />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      {/* <StyledMenu
        id="notification-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {announcements.latest && (
          <StyledNotification>
            <div>
              <Heading.Body2>{announcements.latest.title}</Heading.Body2>
            </div>
            <div style={{ zIndex: '10000' }}>
              <StyledButton
                onClick={() => {
                  window.location = '/ogloszenia';
                }}
              >
                zobacz
              </StyledButton>
            </div>
          </StyledNotification>
        )}
        {announcements.count_unread === 0 && (
          <StyledNotification>
            <Heading.Body2>Brak nowych ogłoszeń do przeczytania</Heading.Body2>
          </StyledNotification>
        )}
      </StyledMenu> */}
    </>
  );
};

AnnouncementIcon.propTypes = {
  announcements: PropTypes.shape({
    count_unread: PropTypes.number,
    latest: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

// PropTypes.arrayOf(
//   PropTypes.shape({
//     id: PropTypes.number,
//     name: PropTypes.string,
//   }),
// ),

AnnouncementIcon.defaultProps = {
  announcements: {
    count_unread: 0,
    latest: null,
  },
};

const mapStateToProps = ({ userReducer }) => ({
  announcements: userReducer.notifications.announcements,
});

export default connect(mapStateToProps)(AnnouncementIcon);
