import {
  FETCH_USER,
  CLEAR_USER,
  FETCH_USERS_LOGIN_STATISTICS,
  CLEAR_USERS_LOGIN_STATISTICS,
  SHOW_USERS,
  CLEAR_USERS,
  FETCH_ME,
  CLEAR_ME,
  FETCH_USER_PERMISSIONS,
  CLEAR_USER_PERMISSIONS,
  FETCH_USER_NOTIFICATIONS,
  SHOW_ANNOUNCEMENTS,
  CLEAR_ANNOUNCEMENTS,
} from '@constants/actionTypes';

export function showUsers(payload) {
  return { type: SHOW_USERS, payload };
}

export function clearUsers(payload) {
  return { type: CLEAR_USERS, payload };
}

export function showAnnouncements(payload) {
  return { type: SHOW_ANNOUNCEMENTS, payload };
}

export function clearAnnouncements(payload) {
  return { type: CLEAR_ANNOUNCEMENTS, payload };
}

export function fetchUsersLoginStatisticsAction(payload) {
  return { type: FETCH_USERS_LOGIN_STATISTICS, payload };
}

export function clearUsersLoginStatistics(payload) {
  return { type: CLEAR_USERS_LOGIN_STATISTICS, payload };
}

export function fetchUserAction(payload) {
  return { type: FETCH_USER, payload };
}

export function clearUser(payload) {
  return { type: CLEAR_USER, payload };
}

export function fetchUserPermissionsAction(payload) {
  return { type: FETCH_USER_PERMISSIONS, payload };
}

export function clearUserPermissions(payload) {
  return { type: CLEAR_USER_PERMISSIONS, payload };
}

export function fetchUserNotificationsAction(payload) {
  return { type: FETCH_USER_NOTIFICATIONS, payload };
}

export function fetchMeAction(payload) {
  return { type: FETCH_ME, payload };
}

export function clearMeAction() {
  return { type: CLEAR_ME };
}
