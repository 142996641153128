// React
import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchAnnouncementsList,
  markAnnouncementAsRead as markAnnouncementAsReadService,
  markAnnouncementAsShown as markAnnouncementAsShownService,
} from '@services/userServices';

// Actions
import { clearAnnouncements as clearAnnouncementsAction } from '@actions/userActions';

// Data
// import { filterUsers } from '@constants/tableFilters';
import { COORDINATOR, ORGANIZATION_ADMINISTRATOR } from '@constants/roles';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';

import Heading from '@components/atoms/Heading/Heading';
import Button from '@components/atoms/Button/Button';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

// Data
const headCells = [
  { type: 'title', label: 'Tytuł' },
  { type: 'issuedAt', label: 'Utworzono' },
  { type: 'createdBy', label: 'Utworzono przez' },
  { type: 'readAt', label: 'Przeczytano' },
  // { type: 'shownAt', label: 'Wyświetlono' },
];

// Component
const Announcements = ({
  clearAnnouncements,
  fetchList,
  announcements,
  markAnnouncementAsShown,
  markAnnouncementAsRead,
}) => {
  const [currentAnnnouncement, setCurrentAnnnouncement] = useState(null);

  useEffect(() => {
    clearAnnouncements();
  }, []);

  const triggerFetchList = useCallback(() => {
    return fetchList();
  }, [fetchList]);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  const actionCells = [
    {
      column: 'zobacz',
      action: (row) => {
        setCurrentAnnnouncement(row);
      },
      button: 'zobacz',
    },
  ];

  return (
    <>
      {announcements.length !== 0 && announcements && (
        <TableTemplate
          headCells={headCells}
          data={announcements}
          actionCells={actionCells}
          defaultSortColumn="issuedAt"
          defaultSortDirection="desc"
          isnav
          disableNav
          tableName="Ogłoszenia"
        />
      )}

      <Dialog open={currentAnnnouncement} onClose={() => {}}>
        <DialogTitle>
          <Heading.H6>
            {currentAnnnouncement?.title !== '' ? currentAnnnouncement?.title : 'Ogłoszenie'}
          </Heading.H6>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Heading.Body2>
              {currentAnnnouncement?.description?.split('\n').map((line) => (
                <React.Fragment key={`'_'${line}`}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Heading.Body2>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              markAnnouncementAsShown(currentAnnnouncement.id);
              setCurrentAnnnouncement(null);
            }}
            color="secondary"
          >
            Przeczytam później
          </Button>
          <Button
            onClick={() => {
              markAnnouncementAsRead(currentAnnnouncement.id);
              setCurrentAnnnouncement(null);
            }}
            color="primary"
          >
            Przeczytałam/em
          </Button>
        </DialogActions>
      </Dialog>

      <GuardedComponent allowed_user_roles={[COORDINATOR, ORGANIZATION_ADMINISTRATOR]}>
        <FloatingButton
          onClick={null}
          component={React.forwardRef((props, ref) => (
            <Link to="/konfiguracja/tab/4-ogloszenia" {...props} ref={ref} />
          ))}
        />
      </GuardedComponent>
    </>
  );
};

Announcements.propTypes = {
  clearAnnouncements: PropTypes.func,
  fetchList: PropTypes.func,

  markAnnouncementAsRead: PropTypes.func,
  markAnnouncementAsShown: PropTypes.func,

  announcements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      surname: PropTypes.string,
      add_date: PropTypes.string,
      email: PropTypes.string,
      latest_user_log: PropTypes.string,
      phone: PropTypes.string,
      user_programs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      user_organizations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      user_roles: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

Announcements.defaultProps = {
  clearAnnouncements: null,
  fetchList: null,
  announcements: [],
  markAnnouncementAsRead: null,
  markAnnouncementAsShown: null,
};

const mapStateToProps = ({ userReducer }) => ({
  // userReducer
  announcements: userReducer.announcements,
});

const mapDispatchToProps = (dispatch) => ({
  clearAnnouncements: () => dispatch(clearAnnouncementsAction()),

  markAnnouncementAsRead: (id) => dispatch(markAnnouncementAsReadService(id)),
  markAnnouncementAsShown: (id) => dispatch(markAnnouncementAsShownService(id)),
  fetchList: () => dispatch(fetchAnnouncementsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
