// React
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Breadcrumbs
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Data
import { organizationTabs as fullRenderView } from '@constants/tabs';

// Elements
import Tabs from '@components/molecules/Tabs/Tabs';

// Component
const OrganizationTabs = ({ currentDetails }) => {
  const { id } = useParams();
  const [filteredRenderViewArray, setFilteredRenderViewArray] = useState(fullRenderView);

  useEffect(() => {
    if (currentDetails && currentDetails.type) {
      if (currentDetails.type === 1 && !currentDetails.parent) {
        setFilteredRenderViewArray(
          fullRenderView.filter((v) => {
            return v.key !== 'koalicje';
          }),
        );
      } else {
        setFilteredRenderViewArray(fullRenderView);
      }
    }
  }, [currentDetails]);

  return (
    <>
      {currentDetails.parent && (
        <BreadcrumbsItem to={`/organizacje/${currentDetails.parent.id}`} order={3}>
          Organizacja koalicyjna: {currentDetails.parent.name}
        </BreadcrumbsItem>
      )}
      <BreadcrumbsItem to={`/organizacje/${id}`} order={4}>
        {`Organizacja: ${currentDetails.name}`}
      </BreadcrumbsItem>

      {filteredRenderViewArray && (
        <Tabs
          organization_id={parseInt(id, 10)}
          parent_organization_id={currentDetails.parent ? currentDetails.parent.id : null}
          renderViewArray={filteredRenderViewArray}
        />
      )}
    </>
  );
};

OrganizationTabs.propTypes = {
  currentDetails: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    business_id: PropTypes.string,
    type: PropTypes.number,
    status: PropTypes.number,
    representative: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      surname: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),

    organization_children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        identificator_number: PropTypes.string,
      }),
    ),
    parent: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    organization_street: PropTypes.string,
    organization_house: PropTypes.string,
    organization_local: PropTypes.string,
    organization_zip_code: PropTypes.string,
    organization_city: PropTypes.string,
    organization_district: PropTypes.string,
    outpost: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      street: PropTypes.string,
      houseNumber: PropTypes.string,
      apartmentNumber: PropTypes.string,
      district: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }),
    identificator_kind: PropTypes.number,
    identificator_number: PropTypes.string,
    is_same_outpost_address: PropTypes.bool,
  }),
};

OrganizationTabs.defaultProps = {
  currentDetails: {},
};

const mapStateToProps = ({ organizationReducer }) => ({
  currentDetails: organizationReducer.organization,
});

export default connect(mapStateToProps)(OrganizationTabs);
