// React
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Context
import { DashboardContext } from '@contexts/DashboardContext';

// Services
import {
  fetchUserNotifications as fetchUserNotificationsService,
  updateUserSession as updateUserSessionService,
  markAnnouncementAsRead as markAnnouncementAsReadService,
  markAnnouncementAsShown as markAnnouncementAsShownService,
} from '@services/userServices';

// Actions
import { changeContrast, changeFontSize } from '@actions/appActions';

// Elements
import {
  Tooltip,
  IconButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import Button from '@components/atoms/Button/Button';
import Dialog from '@components/organisms/Dialog/Dialog';
import Heading from '@components/atoms/Heading/Heading';
import UserIcon from '@components/molecules/UserIcon/UserIcon';
import InvitationIcon from '@components/molecules/InvitationIcon/InvitationIcon';
import NotificationIcon from '@components/molecules/NotificationIcon/NotificationIcon';
import AnnouncementIcon from '@components/molecules/AnnouncementIcon/AnnouncementIcon';
import NavLogo from '@components/organisms/Navbar/NavLogo';

// Styles
import {
  StyledNavbar,
  StyledToolbar,
  StyledMenuIcon,
  StyledTooltip,
  StyledEmailRoundedIcon,
  StyledFontWrapper,
  StyledIconWrapper,
  StyledContrastIcon,
  StyledToolbarElement,
  StyledBadge,
  StyledLinearProggressWrapper,
  StyledLinearProgress,
  StyledIconButton,
} from './Navbar.styles';

// Component
const Navbar = ({
  setContrast,
  setFontSize,
  notifications,
  isFetchPending,
  fetchUserNotifications,
  updateUserSession,
  markAnnouncementAsRead,
  markAnnouncementAsShown,
}) => {
  const { setIsSidebarHide, isSidebarHide } = useContext(DashboardContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isAnnouncementPopupOpen, setIsAnnouncementPopupOpen] = useState(false);
  const [latestAnnouncement, setLatestAnnouncement] = useState(null);

  const changeFont = (type) => {
    const fontSize = parseInt(window.localStorage.getItem('font-size'), 10) || 10;

    if (!type && fontSize > 7) {
      window.localStorage.setItem('font-size', fontSize - 1);

      setFontSize(fontSize - 1);
    } else if (type && fontSize < 13) {
      window.localStorage.setItem('font-size', fontSize + 1);

      setFontSize(fontSize + 1);
    }
  };

  const changeContrastFn = () => {
    const contrast = window.localStorage.getItem('contrast') || false;

    if (contrast === 'true') {
      document.body.classList.remove('contrast-on');
      document.cookie = `bcc=;max-age=604800;domain=.bm2.rodzinnawarszawa.pl`;
      window.localStorage.setItem('contrast', 'false');

      setContrast('false');
    } else {
      document.body.classList.add('contrast-on');
      document.cookie = `bcc=c1;max-age=604800;domain=.bm2.rodzinnawarszawa.pl`;
      window.localStorage.setItem('contrast', 'true');

      setContrast('true');
    }
  };

  const refreshSession = () => {
    updateUserSession();
    // console.log('Sesja została odświeżona!');
    setIsPopupOpen(false);
  };

  useEffect(() => {
    fetchUserNotifications();
    const interval = setInterval(() => {
      fetchUserNotifications();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (notifications.session_seconds_left <= 120) {
      setIsPopupOpen(true);
    }
    if (notifications.session_seconds_left <= 60) {
      setTimeout(() => {
        fetchUserNotifications();
      }, (notifications.session_seconds_left + 1) * 1000);
    }
    if (notifications.session_seconds_left <= 0) {
      fetchUserNotifications();
    }
    if (notifications.announcements.count_unread > 0 && notifications.announcements.latest) {
      setLatestAnnouncement(notifications.announcements.latest);
      setIsAnnouncementPopupOpen(true);
    }
  }, [notifications, notifications.session_seconds_left]);

  // console.log(notifications);

  return (
    <>
      <StyledNavbar position="fixed">
        <StyledToolbar>
          <StyledToolbarElement fluid>
            <StyledIconButton
              edge="start"
              aria-label="menu"
              onClick={() => setIsSidebarHide(!isSidebarHide)}
            >
              <StyledMenuIcon />
            </StyledIconButton>
            <NavLogo />
            <StyledFontWrapper>
              <StyledIconWrapper onClick={() => changeFont(0)}>
                <Heading.Subtitle2>A-</Heading.Subtitle2>
              </StyledIconWrapper>
              <StyledIconWrapper onClick={() => changeFont(1)}>
                <Heading.H6>A+</Heading.H6>
              </StyledIconWrapper>
              <StyledIconWrapper onClick={() => changeContrastFn()}>
                <StyledContrastIcon />
              </StyledIconWrapper>
            </StyledFontWrapper>
          </StyledToolbarElement>
          <StyledToolbarElement icons>
            <Tooltip
              title={
                <StyledTooltip>
                  <Heading.Body1 color="white">Wiadomości</Heading.Body1>
                </StyledTooltip>
              }
            >
              <IconButton edge="start" aria-label="menu" component={Link} to="/wiadomosci">
                <StyledBadge badgeContent={notifications.unread_messages} color="secondary">
                  <StyledEmailRoundedIcon />
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <AnnouncementIcon />
            <InvitationIcon />
            <NotificationIcon />
            <UserIcon />
          </StyledToolbarElement>
        </StyledToolbar>
        <StyledLinearProggressWrapper isPending={isFetchPending}>
          <StyledLinearProgress />
        </StyledLinearProggressWrapper>
      </StyledNavbar>
      <Dialog open={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <DialogTitle>
          <Heading.H5>Ostrzeżenie o braku aktywności</Heading.H5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Heading.Body2>
              Twoja sesja wygasa za <strong>mniej niż 2 minuty</strong>. <br />
              Czy chcesz ją odświeżyć?
            </Heading.Body2>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPopupOpen(false)} color="primary">
            Zamknij
          </Button>
          <Button onClick={refreshSession} color="primary" autoFocus>
            Odśwież sesję
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAnnouncementPopupOpen} onClose={() => {}}>
        <DialogTitle>
          <Heading.H6>
            {latestAnnouncement?.title !== '' ? latestAnnouncement?.title : 'Ogłoszenie'}
          </Heading.H6>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Heading.Body2>
              {latestAnnouncement?.description?.split('\n').map((line) => (
                <React.Fragment key={`'_'${line}`}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Heading.Body2>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              markAnnouncementAsShown(latestAnnouncement.id);
              setIsAnnouncementPopupOpen(false);
            }}
            color="secondary"
          >
            Przeczytam później
          </Button>
          <Button
            onClick={() => {
              markAnnouncementAsRead(latestAnnouncement.id);
              setIsAnnouncementPopupOpen(false);
            }}
            color="primary"
          >
            Przeczytałam/em
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Navbar.propTypes = {
  fetchUserNotifications: PropTypes.func,
  updateUserSession: PropTypes.func,
  markAnnouncementAsRead: PropTypes.func,
  markAnnouncementAsShown: PropTypes.func,
  setContrast: PropTypes.func,
  setFontSize: PropTypes.func,
  isFetchPending: PropTypes.bool.isRequired,
  notifications: PropTypes.shape({
    unread_messages: PropTypes.number,
    session_seconds_left: PropTypes.number,
    announcements: PropTypes.shape({
      count_unread: PropTypes.number,
      latest: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string,
        created_at: PropTypes.string,
      }),
    }),
  }),
};

Navbar.defaultProps = {
  fetchUserNotifications: null,
  updateUserSession: null,
  markAnnouncementAsRead: null,
  markAnnouncementAsShown: null,
  setContrast: null,
  setFontSize: null,
  notifications: {
    unread_messages: 0,
    session_seconds_left: 600,
    announcements: {
      count_unread: 0,
      latest: null,
    },
  },
};

const mapStateToProps = ({ appReducer, userReducer }) => ({
  isFetchPending: appReducer.isPending,
  notifications: userReducer.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserNotifications: () => dispatch(fetchUserNotificationsService()),
  updateUserSession: () => dispatch(updateUserSessionService()),
  markAnnouncementAsRead: (id) => dispatch(markAnnouncementAsReadService(id)),
  markAnnouncementAsShown: (id) => dispatch(markAnnouncementAsShownService(id)),
  setContrast: (e) => dispatch(changeContrast(e)),
  setFontSize: (e) => dispatch(changeFontSize(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
