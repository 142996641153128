import axiosInstance from '@utils/axios';
import { fetchFailure, fetchSuccess, fetchRequest } from '@actions/appActions';
import {
  fetchSubjectListAction,
  setSubjectPendingAction,
  fetchPwdListAction,
  setPwdPendingAction,
  fetchEducationalFacilitiesListAction,
  setEducationalFacilitiesPendingAction,
  fetchBeneficiaryIndicatorPeriodAction,
  fetchAnnouncementsListAction,
  setAnnouncementsPendingAction,
} from '@actions/selectActions';
import { fetchPeriodsAction } from '@actions/indicatorActions';
import { checkStatusCode } from '@utils/checkStatusCode';

export const fetchAnnouncementsList = () => (dispatch) => {
  dispatch(setAnnouncementsPendingAction(true));
  return axiosInstance
    .get('/announcements')
    .then((res) => {
      dispatch(setAnnouncementsPendingAction(false));
      dispatch(fetchAnnouncementsListAction(res.data));
    })
    .catch(() => {
      dispatch(setAnnouncementsPendingAction(false));
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy ogłoszeń'),
          isActive: true,
        }),
      );
    });
};

export const saveAnnouncements = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/announcements/${id}/edytuj`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchAnnouncementsList());

      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować ogłoszenia'),
          isActive: true,
        }),
      );
    });
};

export const deleteAnnouncements = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/announcements/${id}/usun`, null)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchAnnouncementsList());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunać ogłoszenia'),
          isActive: true,
        }),
      );
    });
};

export const fetchSubjectList = () => (dispatch) => {
  dispatch(setSubjectPendingAction(true));
  return axiosInstance
    .get('/przedmioty_szkolne/nowa_lista')
    .then((res) => {
      dispatch(setSubjectPendingAction(false));
      dispatch(fetchSubjectListAction(res.data));
    })
    .catch(() => {
      dispatch(setSubjectPendingAction(false));
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy przedmiotów'),
          isActive: true,
        }),
      );
    });
};

export const saveSubject = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/przedmioty_szkolne/${id}/edytuj`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchSubjectList());

      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować przedmiotu'),
          isActive: true,
        }),
      );
    });
};

export const deleteSubject = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/przedmioty_szkolne/${id}/usun`, null)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchSubjectList());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunać predmiotu'),
          isActive: true,
        }),
      );
    });
};

export const fetchPWDList = () => (dispatch) => {
  dispatch(setPwdPendingAction(true));
  return axiosInstance
    .get('/pwd/nowa_lista')
    .then((res) => {
      dispatch(setPwdPendingAction(false));
      dispatch(fetchPwdListAction(res.data));
    })
    .catch(() => {
      dispatch(setPwdPendingAction(false));
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy placówek wsparcia dziennego'),
          isActive: true,
        }),
      );
    });
};

export const fetchEducationalFacilitiesList = () => (dispatch) => {
  dispatch(setEducationalFacilitiesPendingAction(true));
  return axiosInstance
    .get('/placowki_edukacyjne/nowa_lista')
    .then((res) => {
      dispatch(setEducationalFacilitiesPendingAction(false));
      dispatch(fetchEducationalFacilitiesListAction(res.data));
    })
    .catch(() => {
      dispatch(setEducationalFacilitiesPendingAction(false));
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy placówek edukacyjnych'),
          isActive: true,
        }),
      );
    });
};

export const saveEducationalFacility = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/placowki_edukacyjne/${id}/edytuj`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchEducationalFacilitiesList());

      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować szkoła'),
          isActive: true,
        }),
      );
    });
};

export const deleteEducationalFacility = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/placowki_edukacyjne/${id}/usun`, null)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchEducationalFacilitiesList());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunać szkoły'),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryIndicatorPeriod = (id) => (dispatch) => {
  return axiosInstance
    .get(`/pomiary/wskaznik/${id}`)
    .then((res) => {
      dispatch(fetchBeneficiaryIndicatorPeriodAction(res.data));
    })
    .catch(() => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(null, 'Nie udało się pobrać listy placówek edukacyjnych'),
          isActive: true,
        }),
      );
    });
};

export const fetchPeriods = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/pomiary/nowa_lista`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchPeriodsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const savePeriod = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/pomiary/${id}/edytuj`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchPeriods());

      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować pomiaru'),
          isActive: true,
        }),
      );
    });
};

export const deletePeriod = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/pomiary/${id}/usun`, null)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchPeriods());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunać pomiaru'),
          isActive: true,
        }),
      );
    });
};
