// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Service
import {
  fetchCWPInfo as fetchCWPInfoService,
  saveBeneficiaryDiagnose as saveBeneficiaryDiagnoseService,
  fetchSingleDiagnose as fetchSingleDiagnoseService,
} from '@services/beneficiaryServices';
import {
  fetchProgramsList as fetchProgramsListService,
  fetchProgramBeneficiaries as fetchProgramBeneficiariesService,
  fetchProgramUsersSimple as fetchProgramUsersSimpleService,
} from '@services/programServices';

// Actions
import {
  clearCWPInfo as clearCWPInfoAction,
  clearSingleDiagnose as clearSingleDiagnoseAction,
} from '@actions/beneficiaryActions';
import {
  clearProgramUsers as clearProgramUsersAction,
  clearPrograms as clearProgramsAction,
  clearProgramBeneficiaries as clearProgramBeneficiariesAction,
} from '@actions/programActions';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Utils
import { generateBeneficiaryDiagnosisPDF } from '@utils/functions';

// Data
import {
  diagnosisBasicData,
  diagnosisRecruitmentSource,
  diagnosisChildSituation,
  diagnosisProgramsData,
  diagnosisFamilySituation,
  diagnosisInstitutionCare,
  diagnosisTextareaFields,
} from '@constants/diagnosis';

// Assets
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';

// Elements
import {
  Box,
  Grid,
  RadioGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';
import Calendar from '@components/atoms/Calendar/Calendar';
import Heading from '@components/atoms/Heading/Heading';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import EditButton from '@components/atoms/EditButton/EditButton';

// Styles
import {
  StyledGrid,
  StyledTableCell,
  StyledTextField,
  StyledButton,
  StyledTextareaWrapper,
  StyledTextarea,
  StyledEditWrapper,
  // StyledText,
} from './AddBeneficiaryDiagnosis.styles';
import './AddBeneficiaryTable.css';

// View
const AddBeneficiaryDiagnosis = ({
  clearSingleDiagnose,
  fetchSingleDiagnose,
  single_diagnose,
  clearCWPInfo,
  fetchCWPInfo,
  cwp_info,
  clearProgramBeneficiaries,
  fetchProgramBeneficiaries,
  beneficiaries,
  clearPrograms,
  fetchProgramsList,
  programs,
  clearProgramUsers,
  fetchProgramUsersSimple,
  authors,
  saveBeneficiaryDiagnose,
  me,
}) => {
  const { id, id_diagnose, edit, program_id } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [redirectView, setRedirectView] = useState(true);
  const [programAuthorData, setProgramAuthorData] = useState([]);
  const [programSelectData, setProgramSelectData] = useState([]);
  const [programBeneficiariesData, setProgramBeneficiariesData] = useState([]);
  const [childrenRecruitSource, setChildrenRecruitSource] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [programID, setProgramID] = useState(null);
  const history = useHistory();

  useEffect(() => {
    clearSingleDiagnose();
    clearProgramBeneficiaries();
    clearProgramUsers();
    clearProgramBeneficiaries();
    clearPrograms();
    clearCWPInfo();
  }, []);

  const triggerFetchSingleDiagnose = useCallback(
    (myid) => fetchSingleDiagnose(myid),
    [fetchSingleDiagnose],
  );

  useEffect(() => {
    if ((id_diagnose && !!edit) || !id_diagnose) {
      setIsEditable(true);
    }
  }, [id_diagnose, edit]);

  useEffect(() => {
    if (id_diagnose && Object.keys(single_diagnose).length > 0 && single_diagnose.status > 0) {
      setIsExpanded(true);
    }
  }, [single_diagnose]);

  useEffect(() => {
    if (id_diagnose) {
      triggerFetchSingleDiagnose(id_diagnose);
    }
  }, [id_diagnose, triggerFetchSingleDiagnose]);

  const triggerFetchCWPInfo = useCallback((myid) => fetchCWPInfo(myid), [fetchCWPInfo]);

  useEffect(() => {
    if (program_id) {
      fetchProgramBeneficiaries(program_id);
    }
  }, [program_id]);

  const triggerFetchProgramBeneficiaries = useCallback(
    (myid) => fetchProgramBeneficiaries(myid),
    [fetchProgramBeneficiaries],
  );

  const triggerFetchProgramUsersSimple = useCallback(
    (myid) => fetchProgramUsersSimple(myid),
    [fetchProgramUsersSimple],
  );

  useEffect(() => {
    if (
      program_id ||
      Object.keys(cwp_info).length > 0 ||
      (Object.keys(single_diagnose).length > 0 && single_diagnose.program.id && isEditable)
    ) {
      setProgramID(cwp_info.program_id || program_id || single_diagnose.program.id);
    }
  }, [cwp_info.program_id, single_diagnose, program_id, isEditable]);

  useEffect(() => {
    if (programID) {
      triggerFetchProgramBeneficiaries(programID);
      triggerFetchProgramUsersSimple(programID);
    }
  }, [
    programID,
    cwp_info.program_id,
    program_id,
    triggerFetchProgramBeneficiaries,
    triggerFetchProgramUsersSimple,
  ]);

  const triggerFetchProgramsList = useCallback(() => fetchProgramsList(), [fetchProgramsList]);

  useEffect(() => {
    triggerFetchProgramsList();

    if (id) {
      triggerFetchCWPInfo(id);
    }
  }, [id, triggerFetchCWPInfo, triggerFetchProgramsList]);

  useEffect(() => {
    const newData = [];

    programs.map((el) => {
      if (el.beneficiaries_number > 0) {
        newData.push({ name: el.name, value: el.id });
      }

      return true;
    });

    setProgramSelectData(newData);
  }, [programs]);

  useEffect(() => {
    const newData = [];

    beneficiaries.map((el) => {
      newData.push({
        name: `${el.beneficiary_name} ${el.beneficiary_last_name}`,
        value: el.id,
        cwp_id: el.cwp_id,
      });

      return true;
    });

    setProgramBeneficiariesData(newData);
  }, [beneficiaries]);

  useEffect(() => {
    const newData = [];

    authors.map((el) => {
      newData.push({ name: el.name, value: el.id });

      return true;
    });

    setProgramAuthorData(newData);
  }, [authors]);

  const nextView = async (validateForm, setFn, status) => {
    const errors = await validateForm();

    if (Object.keys(errors).length === 0) {
      setIsExpanded(true);
      setFn('status', status);
    }
  };

  const diagnosisBasicDataAPI =
    (Object.keys(single_diagnose).length > 0 && single_diagnose.rows.basic_data) ||
    (Object.keys(currentItem).length > 0 && currentItem.rows && currentItem.rows.basic_data) ||
    [];
  const diagnosisRecruitmentSourceAPI =
    (Object.keys(single_diagnose).length > 0 && single_diagnose.rows.recruitment_soruce) ||
    (Object.keys(currentItem).length > 0 &&
      currentItem.rows &&
      currentItem.rows.recruitment_soruce) ||
    '';
  const diagnosisRecruitmentSourceTextAPI =
    (Object.keys(single_diagnose).length > 0 && single_diagnose.rows.recruitment_soruce_text) ||
    (Object.keys(currentItem).length > 0 &&
      currentItem.rows &&
      currentItem.rows.recruitment_soruce_text) ||
    '';
  const diagnosisChildSituationAPI =
    (Object.keys(single_diagnose).length > 0 && single_diagnose.rows.child_situation) ||
    (Object.keys(currentItem).length > 0 && currentItem.rows && currentItem.rows.child_situation) ||
    [];
  const diagnosisProgramsDataAPI =
    (Object.keys(single_diagnose).length > 0 && single_diagnose.rows.programs) ||
    (Object.keys(currentItem).length > 0 && currentItem.rows && currentItem.rows.programs) ||
    [];
  const diagnosisFamilySituationAPI =
    (Object.keys(single_diagnose).length > 0 && single_diagnose.rows.family_situation) ||
    (Object.keys(currentItem).length > 0 &&
      currentItem.rows &&
      currentItem.rows.family_situation) ||
    [];
  const diagnosisInstitutionCareAPI =
    (Object.keys(single_diagnose).length > 0 && single_diagnose.rows.institution_care) ||
    (Object.keys(currentItem).length > 0 &&
      currentItem.rows &&
      currentItem.rows.institution_care) ||
    [];
  const diagnosisTextareaFieldsAPI =
    (Object.keys(single_diagnose).length > 0 && single_diagnose.rows.textarea_fields) ||
    (Object.keys(currentItem).length > 0 && currentItem.rows && currentItem.rows.textarea_fields) ||
    [];

  const initVal = {
    id: single_diagnose.id || currentItem.id || null,
    title: single_diagnose.name || currentItem.title || '',
    cwp_id: single_diagnose.cwp_id || currentItem.cwp_id || cwp_info.connection_id || null,
    author:
      (me && `${me.name} ${me.surname}`) ||
      (Object.keys(single_diagnose).length > 0 && single_diagnose.author.name) ||
      null,
    author_id:
      (me && me.id) ||
      (Object.keys(single_diagnose).length > 0 && single_diagnose.author.id) ||
      currentItem.author_id ||
      null,
    program_id:
      (Object.keys(single_diagnose).length > 0 && single_diagnose.program.id) ||
      currentItem.program_id ||
      cwp_info.program_id ||
      parseInt(program_id, 10) ||
      null,
    program:
      (Object.keys(single_diagnose).length > 0 && single_diagnose.program.name) ||
      currentItem.program ||
      cwp_info.program_name ||
      '',
    beneficiary_id:
      (Object.keys(single_diagnose).length > 0 && single_diagnose.beneficiary.id) ||
      currentItem.beneficiary_id ||
      cwp_info.beneficiary_id ||
      null,
    beneficiary:
      (Object.keys(single_diagnose).length > 0 && single_diagnose.beneficiary.name) ||
      currentItem.beneficiary ||
      cwp_info.beneficiary ||
      '',
    execution_date: single_diagnose.execution_date || currentItem.execution_date || '',
    status: single_diagnose.status || currentItem.status || 0,
    rows: {
      basic_data: diagnosisBasicData.map(
        (item) =>
          diagnosisBasicDataAPI.find((el) => el.id === item.id) || { id: item.id, value: false },
      ),
      recruitment_soruce: diagnosisRecruitmentSourceAPI || '',
      recruitment_soruce_text: diagnosisRecruitmentSourceTextAPI || '',
      child_situation: diagnosisChildSituation.map(
        (item) =>
          diagnosisChildSituationAPI.find((el) => el.id === item.id) || {
            id: item.id,
            value: false,
          },
      ),
      programs: diagnosisProgramsData.map(
        (item) =>
          diagnosisProgramsDataAPI.find((el) => el.id === item.id) || {
            id: item.id,
            certificate: false,
            opinion: false,
          },
      ),
      family_situation: diagnosisFamilySituation.map(
        (item) =>
          diagnosisFamilySituationAPI.find((el) => el.id === item.id) || {
            id: item.id,
            value: false,
          },
      ),
      institution_care: diagnosisInstitutionCare.map(
        (item) =>
          diagnosisInstitutionCareAPI.find((el) => el.id === item.id) || {
            id: item.id,
            value: false,
          },
      ),
      textarea_fields: diagnosisTextareaFields.map(
        (item) =>
          diagnosisTextareaFieldsAPI.find((el) => el.id === item.id) || {
            id: item.id,
            value: '',
          },
      ),
    },
  };

  return (
    <>
      <BreadcrumbsItem to="/diagnoza-dodaj" order={4}>
        Dodaj diagnozę
      </BreadcrumbsItem>
      <Box mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            {/*  <StyledText>
              <p>
                Bardzo proszę o scharakteryzowanie dziecka poprzez odpowiedzi na kolejne pytania, na
                ile dany opis trafnie pasuje do danego dziecka. Proszę przez cały czas wypełniania
                odnosić się do tego konkretnego dziecka.
              </p>
              <p>
                Proszę opisać funkcjonowanie dziecka w obecnym czasie (w ciągu ostatniego miesiąca).
                Odpowiadając na pytania, można zachowanie dziecka porównać z innymi dziećmi w
                grupie.
              </p>
            </StyledText> */}
          </Grid>
        </Grid>
      </Box>
      <Formik
        initialValues={initVal}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required('pole wymagane!'),
          author_id: Yup.number('wybierz prowadzącego z listy')
            .nullable(true)
            .required('pole wymagane!')
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          program_id: Yup.number('wybierz program z listy')
            .nullable(true)
            .required('pole wymagane!')
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          beneficiary_id: Yup.number('wybierz beneficjenta z listy')
            .nullable(true)
            .required('pole wymagane!')
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          execution_date: Yup.string().required('pole wymagane!'),
          status: Yup.number(),
          rows: Yup.object().when('status', {
            is: 0,
            then: Yup.object().shape({
              basic_data: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.boolean(),
                  }),
                )
                .test('basic_data_test', null, (obj) => {
                  const objLength = obj.filter((el) => el.value && el);

                  if (objLength.length > 0) {
                    return true;
                  }

                  return new Yup.ValidationError(
                    'zaznacz przynajmniej jedno pole!',
                    null,
                    'basic_data',
                  );
                }),
              recruitment_soruce: Yup.string().required('pole wymagane!'),
              child_situation: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.boolean(),
                  }),
                )
                .test('child_situation_test', null, (obj) => {
                  const objLength = obj.filter((el) => el.value && el);

                  if (objLength.length > 0) {
                    return true;
                  }

                  return new Yup.ValidationError(
                    'zaznacz przynajmniej jedno pole!',
                    null,
                    'child_situation',
                  );
                }),
              family_situation: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.boolean(),
                  }),
                )
                .test('family_situation_test', null, (obj) => {
                  const objLength = obj.filter((el) => el.value && el);

                  if (objLength.length > 0) {
                    return true;
                  }

                  return new Yup.ValidationError(
                    'zaznacz przynajmniej jedno pole!',
                    null,
                    'family_situation',
                  );
                }),
              institution_care: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.boolean(),
                  }),
                )
                .test('institution_care_test', null, (obj) => {
                  const objLength = obj.filter((el) => el.value && el);

                  if (objLength.length > 0) {
                    return true;
                  }

                  return new Yup.ValidationError(
                    'zaznacz przynajmniej jedno pole!',
                    null,
                    'institution_care',
                  );
                }),
            }),
            otherwise: Yup.object().shape({
              basic_data: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.boolean(),
                  }),
                )
                .test('basic_data_test', null, (obj) => {
                  const objLength = obj.filter((el) => el.value && el);

                  if (objLength.length > 0) {
                    return true;
                  }

                  return new Yup.ValidationError(
                    'zaznacz przynajmniej jedno pole!',
                    null,
                    'basic_data',
                  );
                }),
              recruitment_soruce: Yup.string().required('pole wymagane!'),
              child_situation: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.boolean(),
                  }),
                )
                .test('child_situation_test', null, (obj) => {
                  const objLength = obj.filter((el) => el.value && el);

                  if (objLength.length > 0) {
                    return true;
                  }

                  return new Yup.ValidationError(
                    'zaznacz przynajmniej jedno pole!',
                    null,
                    'child_situation',
                  );
                }),
              family_situation: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.boolean(),
                  }),
                )
                .test('family_situation_test', null, (obj) => {
                  const objLength = obj.filter((el) => el.value && el);

                  if (objLength.length > 0) {
                    return true;
                  }

                  return new Yup.ValidationError(
                    'zaznacz przynajmniej jedno pole!',
                    null,
                    'family_situation',
                  );
                }),
              institution_care: Yup.array()
                .of(
                  Yup.object().shape({
                    value: Yup.boolean(),
                  }),
                )
                .test('institution_care_test', null, (obj) => {
                  const objLength = obj.filter((el) => el.value && el);

                  if (objLength.length > 0) {
                    return true;
                  }

                  return new Yup.ValidationError(
                    'zaznacz przynajmniej jedno pole!',
                    null,
                    'institution_care',
                  );
                }),
              textarea_fields: Yup.array().of(
                Yup.object().shape({
                  value: Yup.string().required('pole wymagane!'),
                }),
              ),
            }),
          }),
        })}
        onSubmit={(values) => {
          if ((Object.keys(currentItem).length > 0 || id_diagnose) && !isEditable) {
            return;
          }

          let isEditView = false;

          if ((Object.keys(currentItem).length > 0 || id_diagnose) && isEditable) {
            isEditView = true;
          }

          setCurrentItem((prevState) => ({ ...prevState, ...values }));

          const data = saveBeneficiaryDiagnose(
            values.beneficiary_id,
            values,
            isEditView,
            redirectView,
          );

          if (!redirectView) {
            data.then((res) => {
              setCurrentItem((prevState) => ({ ...prevState, id: res }));
            });
          } else {
            history.push(`/beneficjenci/${values.beneficiary_id}/tab/5-Diagnoza`);
          }
        }}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, validateForm, errors }) => (
          <>
            {values.beneficiary_id && (
              <BreadcrumbsItem to={`/beneficjenci/${values.beneficiary_id}`} order={2}>
                Beneficjent: {values.beneficiary}
              </BreadcrumbsItem>
            )}
            {values.program_id && (
              <BreadcrumbsItem to={`/programy/${values.program_id}`} order={3}>
                Program: {values.program}
              </BreadcrumbsItem>
            )}
            {id_diagnose && single_diagnose.status < 2 && (
              <StyledEditWrapper>
                <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
              </StyledEditWrapper>
            )}
            <StyledGrid container spacing={2} margin="2rem">
              <Grid item xs={12}>
                <Input
                  disabled={!!(id_diagnose && !isEditable)}
                  label="Tytuł"
                  variant="filled"
                  name="title"
                  id="title"
                  onChange={handleChange}
                  value={values.title}
                />
                <ErrorMessage name="title">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                {id_diagnose && !isEditable && values.program ? (
                  <Input
                    disabled
                    label="Nazwa programu"
                    variant="filled"
                    name="program"
                    id="program"
                    onChange={handleChange}
                    value={values.program}
                  />
                ) : (
                  <>
                    <Select
                      disabled={programSelectData.length === 0 || id || !isEditable ? true : null}
                      label="Nazwa programu"
                      data={programSelectData}
                      variant="filled"
                      selectValue={
                        programSelectData.length > 0 && values.program_id ? values.program_id : null
                      }
                      text="program_id"
                      id="program_id"
                      onChange={(e, newValue) => {
                        setFieldValue(e, newValue);
                        const found = programSelectData.find((el) => el.value === newValue);
                        setFieldValue('program', found && found.name);
                      }}
                      setProgramIDFn={setProgramID}
                    />
                    <ErrorMessage name="program_id">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                {me || (id_diagnose && !isEditable && values.author) ? (
                  <Input
                    disabled
                    label="Imię i nazwisko przeprowadzającego"
                    variant="filled"
                    name="author"
                    id="author"
                    onChange={handleChange}
                    value={values.author}
                  />
                ) : (
                  <>
                    <Select
                      disabled={programAuthorData.length === 0 ? true : null}
                      label="Imię i nazwisko przeprowadzającego"
                      data={programAuthorData}
                      variant="filled"
                      selectValue={programAuthorData.length > 0 ? values.author_id : null}
                      text="author_id"
                      id="author_id"
                      onChange={setFieldValue}
                    />
                    <ErrorMessage name="author_id">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </>
                )}
              </Grid>
              <Grid item xs={8}>
                {id || (id_diagnose && !isEditable) ? (
                  <Input
                    disabled
                    label="Imię i nazwisko beneficjenta"
                    variant="filled"
                    name="beneficiary"
                    id="beneficiary"
                    onChange={handleChange}
                    value={values.beneficiary}
                  />
                ) : (
                  <>
                    <Select
                      disabled={
                        programBeneficiariesData.length === 0 || id || !isEditable ? true : null
                      }
                      label="Imię i nazwisko beneficjenta"
                      data={programBeneficiariesData}
                      variant="filled"
                      selectValue={
                        programBeneficiariesData.length > 0 ? values.beneficiary_id : null
                      }
                      text="beneficiary_id"
                      id="beneficiary_id"
                      onChange={(e, newValue) => {
                        setFieldValue(e, newValue);
                        const found = programBeneficiariesData.find((el) => el.value === newValue);
                        setFieldValue('beneficiary', found && found.name);
                        setFieldValue('cwp_id', found && found.cwp_id);
                      }}
                    />
                    <ErrorMessage name="beneficiary_id">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <Calendar
                  disabled={!!(id_diagnose && !isEditable)}
                  label="Data przeprowadzenia"
                  variant="filled"
                  value={values.execution_date}
                  name="execution_date"
                  id="execution_date"
                  onChange={setFieldValue}
                />
                <ErrorMessage name="execution_date">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
            </StyledGrid>
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <Heading.Subtitle1>Podstawa diagnozy</Heading.Subtitle1>
              </Grid>
              {diagnosisBasicData.map((el, index) => (
                <React.Fragment key={el.id}>
                  <Grid item xs={12}>
                    <Checkbox
                      isEditable={!(id_diagnose && !isEditable)}
                      label={el.label}
                      name={`rows.basic_data[${index}].value`}
                      value={values.rows.basic_data[index].value === true}
                      onChange={handleChange}
                    />
                  </Grid>
                  {el.textField && values.rows.basic_data[index].value && (
                    <Grid item xs={6}>
                      <Input
                        disabled={!isEditable}
                        variant="filled"
                        label="Wpisz"
                        name={`rows.basic_data[${index}].textValue`}
                        id={`rows.basic_data[${index}].textValue`}
                        onChange={handleChange}
                        value={values.rows.basic_data[index].textValue}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
              {errors.basic_data && (
                <Grid item xs={12}>
                  <div className="error-txt">{errors.basic_data}</div>
                </Grid>
              )}
            </StyledGrid>
            <RadioGroup
              aria-label="Źródło rekrutacji dziecka"
              name="rows.recruitment_soruce"
              value={childrenRecruitSource}
              onChange={(e) => setChildrenRecruitSource(e.target.value)}
            >
              <StyledGrid container spacing={2}>
                <Grid item xs={12}>
                  <Heading.Subtitle1>Źródło rekrutacji dziecka</Heading.Subtitle1>
                </Grid>
                {diagnosisRecruitmentSource.map((el) => (
                  <React.Fragment key={el.id}>
                    <Grid item xs={12}>
                      <Checkbox
                        radio
                        isEditable={!(id_diagnose && !isEditable)}
                        label={el.label}
                        value={values.rows.recruitment_soruce === el.id}
                        fieldValue={el.id}
                        onChange={(e) => setFieldValue('rows.recruitment_soruce', e.target.value)}
                      />
                    </Grid>
                    {el.textField && values.rows.recruitment_soruce === el.id && (
                      <Grid item xs={6}>
                        <Input
                          disabled={!isEditable}
                          variant="filled"
                          label="Wpisz"
                          name="rows.recruitment_soruce_text"
                          id="rows.recruitment_soruce_text"
                          onChange={handleChange}
                          value={values.rows.recruitment_soruce_text}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
                <Grid item xs={12}>
                  <ErrorMessage name="rows.recruitment_soruce">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              </StyledGrid>
            </RadioGroup>
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <Heading.Subtitle1>Sytuacja dziecka</Heading.Subtitle1>
              </Grid>
              {diagnosisChildSituation.map((el, index) => (
                <Grid key={el.id} item xs={6}>
                  <Checkbox
                    isEditable={!(id_diagnose && !isEditable)}
                    label={el.label}
                    name={`rows.child_situation[${index}].value`}
                    value={values.rows.child_situation[index].value === true}
                    onChange={handleChange}
                  />
                </Grid>
              ))}
              {errors.child_situation && (
                <Grid item xs={12}>
                  <div className="error-txt">{errors.child_situation}</div>
                </Grid>
              )}
            </StyledGrid>
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <Heading.Subtitle1>Orzeczenia i opinie</Heading.Subtitle1>
              </Grid>
              <Grid item xs={8}>
                <TableContainer className="tabelaBene" component={Paper}>
                  <Table aria-label="Pogramy z orzeczeniami i opiniami">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Heading.Subtitle1>Program</Heading.Subtitle1>
                        </TableCell>
                        <TableCell align="right">
                          <Heading.Subtitle1>Orzeczenie</Heading.Subtitle1>
                        </TableCell>
                        <TableCell align="right">
                          <Heading.Subtitle1>Opinia</Heading.Subtitle1>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {diagnosisProgramsData.map((el, index) => (
                        <React.Fragment key={el.id}>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Heading.Body2>{el.label}</Heading.Body2>
                            </TableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                isEditable={!(id_diagnose && !isEditable)}
                                name={`rows.programs[${index}].certificate`}
                                value={values.rows.programs[index].certificate === true}
                                onChange={handleChange}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                isEditable={!(id_diagnose && !isEditable)}
                                name={`rows.programs[${index}].opinion`}
                                value={values.rows.programs[index].opinion === true}
                                onChange={handleChange}
                              />
                            </StyledTableCell>
                          </TableRow>
                          {(values.rows.programs[index].certificate ||
                            values.rows.programs[index].opinion) &&
                            el.textField && (
                              <TableRow>
                                <StyledTableCell rowSpan={1} colSpan={3} component="th" scope="row">
                                  <StyledTextField
                                    disabled={!!(id_diagnose && !isEditable)}
                                    variant="filled"
                                    label="Wpisz jakie"
                                    multiline
                                    rows={6}
                                    name={`rows.programs[${index}].textValue`}
                                    id={`rows.programs[${index}].textValue`}
                                    value={values.rows.programs[index].textValue}
                                    onChange={handleChange}
                                  />
                                </StyledTableCell>
                              </TableRow>
                            )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </StyledGrid>
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <Heading.Subtitle1>Sytuacja rodziny</Heading.Subtitle1>
              </Grid>
              {diagnosisFamilySituation.map((el, index) => (
                <Grid key={el.id} item xs={6}>
                  <Checkbox
                    isEditable={!(id_diagnose && !isEditable)}
                    label={el.label}
                    name={`rows.family_situation[${index}].value`}
                    value={values.rows.family_situation[index].value === true}
                    onChange={handleChange}
                  />
                </Grid>
              ))}
              {errors.family_situation && (
                <Grid item xs={12}>
                  <div className="error-txt">{errors.family_situation}</div>
                </Grid>
              )}
            </StyledGrid>
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <Heading.Subtitle1>
                  Czy rodzina jest pod opieką instytucji wspierającej?
                </Heading.Subtitle1>
              </Grid>
              {diagnosisInstitutionCare.map((el, index) => (
                <Grid key={el.id} item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Checkbox
                        isEditable={!(id_diagnose && !isEditable)}
                        label={el.label}
                        name={`rows.institution_care[${index}].value`}
                        value={values.rows.institution_care[index].value === true}
                        onChange={(e, v) => {
                          if (el.disableRest && v) {
                            values.rows.institution_care.forEach((_, i) =>
                              setFieldValue(`rows.institution_care[${i}].value`, false),
                            );
                          } else {
                            diagnosisInstitutionCare.forEach((ele, i) => {
                              if (ele.disableRest) {
                                setFieldValue(`rows.institution_care[${i}].value`, false);
                              }
                            });
                          }
                          handleChange(e, v);
                        }}
                      />
                    </Grid>
                    {el.textField && values.rows.institution_care[index].value && (
                      <Grid item xs={6}>
                        <Input
                          disabled={!isEditable}
                          variant="filled"
                          label="Wpisz"
                          name={`rows.institution_care[${index}].textValue`}
                          id={`rows.institution_care[${index}].textValue`}
                          onChange={handleChange}
                          value={values.rows.institution_care[index].textValue}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
              {errors.institution_care && (
                <Grid item xs={12}>
                  <div className="error-txt">{errors.institution_care}</div>
                </Grid>
              )}
              <Grid item xs={12} />
              {!isExpanded && isEditable && (
                <>
                  <Grid item xs={12}>
                    <StyledButton
                      variant="outlined"
                      onClick={() => {
                        saveBeneficiaryDiagnose(values.beneficiary_id, values, false, false).then(
                          (res) => {
                            setCurrentItem((prevState) => ({ ...prevState, id: res }));
                            history.push(`/diagnoza/${res}`);
                          },
                        );
                      }}
                      disabled={
                        values.title === '' ||
                        !values.cwp_id ||
                        !values.program_id ||
                        values.program === ''
                      }
                    >
                      ZAPISZ SZKIC
                    </StyledButton>
                    {/* <StyledButton
                      margin="2rem"
                      variant="outlined"
                      onClick={() => {
                        setRedirectView(true);
                        handleSubmit();
                      }}
                    >
                      ZAPISZ, aby powrócić do edycji 
                    </StyledButton> */}
                    <StyledButton
                      margin="2rem"
                      padding="1rem 1.5rem"
                      variant="contained"
                      onClick={() => {
                        nextView(validateForm, setFieldValue, 1);
                        setRedirectView(false);
                        handleSubmit();
                      }}
                    >
                      PRZEJDŹ DALEJ
                    </StyledButton>
                  </Grid>
                </>
              )}
              {isExpanded && (
                <>
                  {diagnosisTextareaFields.map((el, index) => (
                    <StyledTextareaWrapper key={el.id} item xs={12}>
                      <Heading.Subtitle1>{el.label}</Heading.Subtitle1>
                      <StyledTextarea
                        disabled={!!(id_diagnose && !isEditable)}
                        label="Wpisz"
                        rows={10}
                        variant="filled"
                        multiline
                        name={`rows.textarea_fields[${index}].value`}
                        setField={setFieldValue}
                        charCounter={el.charCounter}
                        value={values.rows.textarea_fields[index].value}
                      />
                      <ErrorMessage name={`rows.textarea_fields[${index}].value`}>
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </StyledTextareaWrapper>
                  ))}
                  {(!id_diagnose || (id_diagnose && isEditable)) && (
                    <Grid item xs={12}>
                      <StyledButton
                        variant="outlined"
                        onClick={() => {
                          saveBeneficiaryDiagnose(values.beneficiary_id, values, false, false);
                        }}
                      >
                        ZAPISZ SZKIC
                      </StyledButton>
                      {/* <StyledButton
                        variant="outlined"
                        onClick={() => {
                          setRedirectView(true);
                          handleSubmit();
                        }}
                      >
                        ZAPISZ
                      </StyledButton> */}
                      <StyledButton
                        margin="2rem"
                        padding="1rem 1.5rem"
                        variant="contained"
                        onClick={() => {
                          setFieldValue('status', 2);
                          setRedirectView(true);
                          handleSubmit();
                        }}
                      >
                        ZAPISZ I ZAKOŃCZ
                      </StyledButton>
                    </Grid>
                  )}
                  {id_diagnose && single_diagnose.status === 2 && (
                    <Grid item xs={12}>
                      <StyledButton
                        padding="1rem 1.5rem"
                        variant="outlined"
                        startIcon={<PictureAsPdfRoundedIcon />}
                        onClick={() => {
                          generateBeneficiaryDiagnosisPDF(single_diagnose);
                        }}
                      >
                        POBIERZ PDF
                      </StyledButton>
                    </Grid>
                  )}
                </>
              )}
            </StyledGrid>
          </>
        )}
      </Formik>
    </>
  );
};

AddBeneficiaryDiagnosis.propTypes = {
  clearSingleDiagnose: PropTypes.func,
  fetchSingleDiagnose: PropTypes.func,
  single_diagnose: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    cwp_id: PropTypes.number,
    author: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    program: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    beneficiary: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    execution_date: PropTypes.string,
    status: PropTypes.number,
    rows: PropTypes.shape({
      basic_data: PropTypes.arrayOf(PropTypes.any),
      recruitment_soruce: PropTypes.string,
      recruitment_soruce_text: PropTypes.string,
      child_situation: PropTypes.arrayOf(PropTypes.any),
      programs: PropTypes.arrayOf(PropTypes.any),
      family_situation: PropTypes.arrayOf(PropTypes.any),
      institution_care: PropTypes.arrayOf(PropTypes.any),
      family_type: PropTypes.string,
      textarea_fields: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
  clearCWPInfo: PropTypes.func,
  fetchCWPInfo: PropTypes.func,
  cwp_info: PropTypes.shape({
    approvals: PropTypes.shape({
      approvalToIndependentReturnHome: PropTypes.bool,
      approvalToTransferOfInformation: PropTypes.bool,
      approvalToUseImage: PropTypes.bool,
      consortiumInformationClause: PropTypes.bool,
      presidentsInformationClause: PropTypes.bool,
    }),
    assigned_by_school: PropTypes.bool,
    beneficiary: PropTypes.string,
    beneficiaryType: PropTypes.number,
    beneficiary_id: PropTypes.number,
    connection_id: PropTypes.number,
    date_added: PropTypes.string,
    date_added_by_school_to_program: PropTypes.string,
    program_date_end: PropTypes.string,
    program_id: PropTypes.number,
    program_name: PropTypes.string,
    program_date_start: PropTypes.string,
    who_assigned: PropTypes.shape({
      city: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  }),
  clearProgramBeneficiaries: PropTypes.func,
  fetchProgramBeneficiaries: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  clearPrograms: PropTypes.func,
  fetchProgramsList: PropTypes.func,
  programs: PropTypes.arrayOf(PropTypes.any),
  clearProgramUsers: PropTypes.func,
  fetchProgramUsersSimple: PropTypes.func,
  authors: PropTypes.arrayOf(PropTypes.any),
  saveBeneficiaryDiagnose: PropTypes.func,
  me: PropTypes.objectOf(PropTypes.any),
};

AddBeneficiaryDiagnosis.defaultProps = {
  clearSingleDiagnose: null,
  fetchSingleDiagnose: null,
  single_diagnose: {},
  clearCWPInfo: null,
  fetchCWPInfo: null,
  cwp_info: {},
  clearProgramBeneficiaries: null,
  fetchProgramBeneficiaries: null,
  beneficiaries: [],
  clearPrograms: null,
  fetchProgramsList: null,
  programs: [],
  clearProgramUsers: null,
  fetchProgramUsersSimple: null,
  authors: [],
  saveBeneficiaryDiagnose: null,
  me: {},
};

const mapStateToProps = ({ beneficiaryReducer, programReducer, userReducer }) => ({
  single_diagnose: beneficiaryReducer.single_diagnose,
  cwp_info: beneficiaryReducer.cwp_info,
  beneficiaries: programReducer.beneficiaries.program_beneficiaries,
  programs: programReducer.programs,
  authors: programReducer.users,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearSingleDiagnose: () => dispatch(clearSingleDiagnoseAction()),
  fetchSingleDiagnose: (id) => dispatch(fetchSingleDiagnoseService(id)),
  clearCWPInfo: () => dispatch(clearCWPInfoAction()),
  fetchCWPInfo: (id) => dispatch(fetchCWPInfoService(id)),
  clearProgramBeneficiaries: () => dispatch(clearProgramBeneficiariesAction()),
  fetchProgramBeneficiaries: (id) => dispatch(fetchProgramBeneficiariesService(id)),
  clearPrograms: () => dispatch(clearProgramsAction()),
  fetchProgramsList: () => dispatch(fetchProgramsListService()),
  clearProgramUsers: () => dispatch(clearProgramUsersAction()),
  fetchProgramUsersSimple: (id) => dispatch(fetchProgramUsersSimpleService(id)),
  saveBeneficiaryDiagnose: (id, data, edit, redirectView) =>
    dispatch(saveBeneficiaryDiagnoseService(id, data, edit, redirectView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBeneficiaryDiagnosis);
