// React
import React from 'react';
import PropTypes from 'prop-types';

// Import
import slugify from 'slugify';

import { connect } from 'react-redux';
// Data
import { reportTypes } from '@views/Dashboard/Reports/Reports.data';

// Elements
import { Grid, List, CardContent } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import {
  StyledWrapper,
  StyledTitle,
  StyledListItem,
  StyledTitleText,
  StyledLink,
  StyledCard,
  StyledIconWrapper,
} from './Reports.styles';

// Component
const Reports = ({ me }) => {
  console.log(
    me,
    me.user_organizations.length,
    me.user_programs.length,
    me.user_guardians.length,
    me.user_role.includes('ROLE_SONATA_COORDINATOR'),
  );
  return (
    <>
      <StyledWrapper container spacing={2}>
        {reportTypes.map(({ id, label, color, icon, items, programGuardianAllowed }) => (
          <>
            {(me.user_role.includes('ROLE_SONATA_COORDINATOR') ||
              me.user_programs.length > 0 ||
              me.user_organizations.length > 0 ||
              programGuardianAllowed ||
              (programGuardianAllowed && me.user_guardians && me.user_guardians.length > 0)) && (
              <Grid key={id} item xs={4}>
                <StyledCard>
                  <StyledTitle color={color}>
                    <StyledIconWrapper>{icon}</StyledIconWrapper>
                    <StyledTitleText>{label}</StyledTitleText>
                  </StyledTitle>
                  <CardContent>
                    <List>
                      {items.map((item) => (
                        <>
                          {(me.user_role.includes('ROLE_SONATA_COORDINATOR') ||
                            me.user_programs.length > 0 ||
                            me.user_organizations.length > 0 ||
                            item.programGuardianAllowed ||
                            (item.programGuardianAllowed &&
                              me.user_guardians &&
                              me.user_guardians.length > 0)) && (
                            <StyledListItem key={item.id} color={color}>
                              <Heading.Body1>
                                <StyledLink
                                  to={`/raporty-stworz/${slugify(item.label, {
                                    lower: true,
                                    locale: 'pl',
                                    trim: true,
                                  })}`}
                                >
                                  {item.label}
                                </StyledLink>
                              </Heading.Body1>
                            </StyledListItem>
                          )}
                        </>
                      ))}
                    </List>
                  </CardContent>
                </StyledCard>
              </Grid>
            )}
          </>
        ))}
      </StyledWrapper>
    </>
  );
};

Reports.propTypes = {
  me: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    user_programs: PropTypes.arrayOf(PropTypes.any),
    user_guardians: PropTypes.arrayOf(PropTypes.any),
    user_organizations: PropTypes.arrayOf(PropTypes.any),
    user_role: PropTypes.arrayOf(PropTypes.string),
  }),
};

Reports.defaultProps = {
  me: {
    id: null,
    name: null,
    surname: null,
    user_programs: [],
    user_organizations: [],
    user_guardians: [],
    user_role: [],
  },
};
const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

export default connect(mapStateToProps)(Reports);
