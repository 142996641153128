import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import { connect } from 'react-redux';
import Heading from '@components/atoms/Heading/Heading';
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import {
  fetchAnnouncementsList as fetchAnnouncementsListService,
  saveAnnouncements as saveAnnouncementsService,
  deleteAnnouncements as deleteAnnouncementsService,
} from '@services/selectServices';
import { clearAnnouncementsList as clearAnnouncementsListAction } from '@actions/selectActions';
import { StyledTitle, StyledWrapper } from './AnnouncementsSettings.styles';
import './AnnouncementsSettings.scss';

const headCells = [
  { type: 'title', label: 'Tytuł' },
  { type: 'issuedAt', label: 'Utworzono' },
  { type: 'editedAt', label: 'Edytowano' },
  { type: 'createdBy', label: 'Utworzono przez' },
  { type: 'readAt', label: 'Przeczytano' },
  { type: 'shownAt', label: 'Wyświetlono' },
];
const AnnouncementsSettings = ({
  clearAnnouncementsList,
  fetchAnnouncementsList,
  announcements,
  saveAnnouncements,
  deleteAnnouncements,
}) => {
  const [currentAnnnouncement, setCurrentAnnnouncement] = useState(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const triggerFetchAnnouncements = useCallback(
    () => fetchAnnouncementsList(),
    [fetchAnnouncementsList],
  );

  useEffect(() => {
    clearAnnouncementsList();
    triggerFetchAnnouncements();
  }, [triggerFetchAnnouncements]);

  const handleDeleteClick = (Announcements) => {
    setSelectedAnnouncement(Announcements);
    setDeleteDialogOpen(true);
  };

  const handleEditClick = (Announcements) => {
    setSelectedAnnouncement(Announcements);
    setEditDialogOpen(true);
  };

  const confirmDelete = () => {
    setDeleteDialogOpen(false);
    deleteAnnouncements(selectedAnnouncement.id);
  };

  const handleEditSave = () => {
    setEditDialogOpen(false);
    saveAnnouncements(selectedAnnouncement.id, selectedAnnouncement);
  };

  return (
    <StyledWrapper className="Announcements" container spacing={2}>
      <Grid item xs={10}>
        <StyledTitle>
          <Heading.Subtitle1>Ogłoszenia</Heading.Subtitle1>
        </StyledTitle>
        <TableTemplate
          headCells={headCells}
          data={announcements}
          isnav
          disableNav
          tableName="Ogłoszenia"
          noFilter
          rowActions={[
            {
              name: 'zobacz',
              action: (row) => {
                setCurrentAnnnouncement(row);
              },
            },
            {
              name: 'edytuj',
              action: handleEditClick,
            },
            {
              name: 'usuń',
              action: handleDeleteClick,
            },
          ]}
        />
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz usunąć przedmiot `{selectedAnnouncement?.name}`?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Usuń
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit/Add Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>
          {selectedAnnouncement?.id === 'new' ? 'Dodaj Ogłoszenie' : 'Edytuj Ogłoszenie'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Tytuł"
            fullWidth
            margin="normal"
            value={selectedAnnouncement?.title || ''}
            onChange={(e) =>
              setSelectedAnnouncement({ ...selectedAnnouncement, title: e.target.value })
            }
          />
          <TextField
            label="Treść"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={selectedAnnouncement?.description || ''}
            onChange={(e) =>
              setSelectedAnnouncement({ ...selectedAnnouncement, description: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={currentAnnnouncement} onClose={() => {}}>
        <DialogTitle>
          <Heading.H6>
            {currentAnnnouncement?.title !== '' ? currentAnnnouncement?.title : 'Ogłoszenie'}
          </Heading.H6>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Heading.Body2>
              {currentAnnnouncement?.description?.split('\n').map((line) => (
                <React.Fragment key={`'_'${line}`}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Heading.Body2>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCurrentAnnnouncement(null);
            }}
            color="primary"
            autoFocus
          >
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>

      {/* Floating Button for Adding New Subject */}
      <FloatingButton
        onClick={() => {
          setSelectedAnnouncement({ id: 'new' });
          setEditDialogOpen(true);
        }}
      />
    </StyledWrapper>
  );
};

AnnouncementsSettings.propTypes = {
  clearAnnouncementsList: PropTypes.func,
  fetchAnnouncementsList: PropTypes.func,
  announcements: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  saveAnnouncements: PropTypes.func,
  deleteAnnouncements: PropTypes.func,
};

AnnouncementsSettings.defaultProps = {
  clearAnnouncementsList: null,
  fetchAnnouncementsList: null,
  announcements: [],
  saveAnnouncements: null,
  deleteAnnouncements: null,
};

const mapStateToProps = ({ selectReducer }) => ({
  announcements: selectReducer.announcementsList,
});

const mapDispatchToProps = (dispatch) => ({
  clearAnnouncementsList: () => dispatch(clearAnnouncementsListAction()),
  fetchAnnouncementsList: () => dispatch(fetchAnnouncementsListService()),
  saveAnnouncements: (id, data) => dispatch(saveAnnouncementsService(id, data)),
  deleteAnnouncements: (id) => dispatch(deleteAnnouncementsService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementsSettings);
