// React
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchActionMembers as fetchActionMembersService,
  addBeneficiaries as addBeneficiariesService,
  endBeneficiaryParticipationInAction as endBeneficiaryParticipationInActionService,
  removeBeneficiaryFromAction as removeBeneficiaryFromActionService,
} from '@services/actionServices';
import { fetchBeneficiaryListByProgram as fetchBeneficiaryListService } from '@services/beneficiaryServices';

// Actions
import { clearActionMembers as clearActionMembersAction } from '@actions/actionActions';
import { clearBeneficiaries as clearBeneficiariesAction } from '@actions/beneficiaryActions';

// Data
import { filterBeneficiary } from '@constants/tableFilters';
import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
} from '@constants/roles';
import {
  WORKING_WITH_FAMILY,
  COOPERATION_IN_SYSTEM_ASSISTANCE,
} from '@constants/ActionBuildInType';

// Assets
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// Elements
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import { Grid } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import TableTemplate from '@templates/TableTemplate';
import DialogBeneficiaryNotes from '@components/templates/DialogBeneficiaryNotes/DialogBeneficiaryNotes';
import DialogBeneficiaryProgram from '@components/templates/DialogBeneficiaryProgram/DialogBeneficiaryProgram';
import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';
import DialogEditDate from '@components/templates/DialogEditDate/DialogEditDate';

import { isCoordinator } from '@utils/functions';

import { showAlert as showAlertAction } from '@actions/appActions';

import { NIE_MASZ_UPRAWNIEN, ZANONIMIZOWANO_ZAKAZ_EDYCJI } from '@constants/alerts';

// Styles
import { StyledButton } from './ActionBeneficiaries.styles';

// Data
const headCellsTop = [
  { type: 'name', label: 'Imię' },
  { type: 'surname', label: 'Nazwisko' },
  { type: 'gender', label: 'Płeć' },
  { type: 'date_start', label: 'Data rozpoczęcia' },
  { type: 'date_end', label: 'Data zakończenia' },
  { type: 'active', label: 'Aktywny', align: 'center' },
];
const headCellsBottom = [
  { type: 'name', label: 'Imię' },
  { type: 'surname', label: 'Nazwisko' },
  { type: 'gender', label: 'Płeć' },
  { type: 'cwp', label: 'W programie' },
];
const headCellsMeetings = [
  { type: 'name', label: 'Imię' },
  { type: 'surname', label: 'Nazwisko' },
  { type: 'organization', label: 'Instytucja / Organizacja' },
  { type: 'date', label: 'Data' },
];

// Component
const ActionBeneficiaries = ({
  action,
  clearActionMembers,
  fetchMembers,
  members,
  clearBeneficiaries,
  fetchBeneficiaryList,
  beneficiaries,
  addBeneficiaries,
  removeBeneficiaryFromAction,
  endBeneficiaryParticipationInAction,
  membersLoaded,
  beneficiariesLoaded,
  showAlert,
  me,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [cwpID, setCwpID] = useState(null);
  const [beneficiaryID, setBeneficiaryID] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogProgram, setOpenDialogProgram] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [openDialogConfirmEnd, setOpenDialogConfirmEnd] = useState(false);
  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(false);
  const [actionProgramID, setActionProgramID] = useState(null);
  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);
  const [openDialogEditDate, setOpenDialogEditDate] = useState(false);
  const [beneficiaryData, setBeneficiaryData] = useState(null);

  const triggerFetchMembers = useCallback((myid) => fetchMembers(myid), [fetchMembers]);

  useEffect(() => {
    clearActionMembers();
    clearBeneficiaries();
  }, []);

  useEffect(() => {
    triggerFetchMembers(id);
  }, [id]);

  useEffect(() => {
    if (
      action &&
      action.program &&
      action.program.id &&
      action.build_in_type !== 'COOPERATION_IN_SYSTEM_ASSISTANCE'
    ) {
      fetchBeneficiaryList(action.program.id);
    }
  }, [action]);

  useEffect(() => {
    if (
      membersLoaded &&
      beneficiariesLoaded &&
      action &&
      action.build_in_type !== 'COOPERATION_IN_SYSTEM_ASSISTANCE'
    ) {
      const newData = beneficiaries.filter(
        (b) => !members.find((a) => a.beneficiary_id === b.id && a.active),
      );
      setFilteredBeneficiaries(newData);
    }
  }, [membersLoaded, beneficiariesLoaded, action, members, beneficiaries]);

  return (
    <>
      <Grid container spacing={2}>
        {action.build_in_type !== WORKING_WITH_FAMILY &&
        action.build_in_type !== COOPERATION_IN_SYSTEM_ASSISTANCE ? (
          <Grid item xs={12}>
            {members && (
              <TableTemplate
                headCells={headCellsTop}
                data={members}
                filters={filterBeneficiary}
                // navCells
                tableName="Beneficjenci"
                // link="/beneficjenci"
                isnav={false}
                rowActions={
                  action.build_in_type !== WORKING_WITH_FAMILY &&
                  action.build_in_type !== COOPERATION_IN_SYSTEM_ASSISTANCE
                    ? [
                        {
                          name: 'zobacz beneficjenta',
                          action: (row) => {
                            history.push(`/beneficjenci/${row.beneficiary_id}`);
                          },
                        },
                        me.user_role.find(
                          (e) =>
                            e === PROGRAM_COORDINATOR ||
                            e === TASK_COORDINATOR ||
                            e === LECTURER_SENSITIVE_DATA ||
                            e === COORDINATOR,
                        )
                          ? {
                              name: 'dodaj notatkę',
                              action: (row) => {
                                if (
                                  isCoordinator(me.user_role) ||
                                  me.user_programs.find(
                                    (p) =>
                                      p.program_id === action.program.id &&
                                      (p.role === PROGRAM_COORDINATOR ||
                                        p.role === TASK_COORDINATOR ||
                                        p.role === LECTURER_SENSITIVE_DATA),
                                  )
                                ) {
                                  setBeneficiaryID(row.beneficiary_id);
                                  setCwpID(row.cwp_id);
                                  setOpenDialog(true);
                                } else {
                                  showAlert(NIE_MASZ_UPRAWNIEN);
                                }
                              },
                            }
                          : {},
                        {
                          name: 'edytuj datę rozpoczęcia',
                          action: (row) => {
                            // console.log(row);
                            if (row.name === 'anonimizacja') {
                              showAlert(ZANONIMIZOWANO_ZAKAZ_EDYCJI);
                            } else {
                              setBeneficiaryData({
                                ...row,
                                action_id: parseInt(id, 10),
                                action_start: action.start_date,
                              });
                              setOpenDialogEditDate(true);
                            }
                          },
                        },
                        {
                          name: 'wypisz beneficjenta z działania',
                          action: (row) => {
                            setCurrentItem(row);
                            setOpenDialogConfirmEnd(true);
                          },
                        },
                        {
                          name: 'usuń beneficjenta z działania',
                          action: (row) => {
                            setCurrentItem(row);
                            setOpenDialogConfirmRemove(true);
                          },
                        },
                      ]
                    : [
                        {
                          name: 'zobacz beneficjenta',
                          action: (row) => {
                            history.push(`/beneficjenci/${row.beneficiary_id}`);
                          },
                        },
                      ]
                }
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={12}>
            {members && (
              <TableTemplate
                headCells={headCellsMeetings}
                data={members}
                tableName="Uczestnicy"
                isnav={false}
              />
            )}
          </Grid>
        )}
        {action.build_in_type !== WORKING_WITH_FAMILY &&
          action.build_in_type !== COOPERATION_IN_SYSTEM_ASSISTANCE &&
          action.program &&
          !action.program.wasAnonymized && (
            <GuardedComponent
              allowed_user_roles={[COORDINATOR]}
              program_id={action.program && action.program.id}
              allowed_program_roles={[
                PROGRAM_COORDINATOR,
                TASK_COORDINATOR,
                LECTURER_SENSITIVE_DATA,
                LECTURER,
              ]}
            >
              <Grid item xs={12}>
                <Heading.Subtitle1>Dodaj uczestników </Heading.Subtitle1>
                <p>
                  Aby dodać uczestnika do zadania najpierw należy go przypisać do programu. Dodając
                  uczestnika do działania automatycznie dodawany jest do zadania.
                </p>
              </Grid>

              <Grid item container spacing={2} xs={12}>
                <StyledButton
                  startIcon={<AddCircleIcon />}
                  onClick={() => {
                    addBeneficiaries(id, {
                      beneficiaries: selectedBeneficiaries.map((el) => ({
                        cwp_id: el.cwp_id,
                      })),
                    });
                    setSelectedBeneficiaries([]);
                  }}
                >
                  DODAJ ZAZNACZONYCH
                </StyledButton>
                <StyledButton
                  startIcon={<PersonAddIcon />}
                  onClick={() => history.push('/beneficjenci-dodaj')}
                >
                  DODAJ NOWEGO BENEFICJENTA
                </StyledButton>
              </Grid>

              <Grid item xs={12}>
                {filteredBeneficiaries && (
                  <TableTemplate
                    headCells={headCellsBottom}
                    data={filteredBeneficiaries}
                    filters={[...filterBeneficiary, { key: 'cwp', label: 'W programie' }]}
                    updateSelected={setSelectedBeneficiaries}
                    selectedRows={selectedBeneficiaries}
                    tableName="Beneficjenci"
                    rowActions={[
                      {
                        name: 'przypisz',
                        action: (row) => {
                          if (row.cwp_id) {
                            return addBeneficiaries(id, {
                              beneficiaries: [{ cwp_id: row.cwp_id }],
                            });
                          }
                          setBeneficiaryID(row.id);
                          setActionProgramID(action.program.id);
                          setOpenDialogProgram(true);

                          return true;
                        },
                      },
                    ]}
                    select
                  />
                )}
              </Grid>
            </GuardedComponent>
          )}
      </Grid>
      {action.build_in_type !== WORKING_WITH_FAMILY &&
        action.build_in_type !== COOPERATION_IN_SYSTEM_ASSISTANCE && (
          <>
            {openDialogProgram && (
              <DialogBeneficiaryProgram
                beneficiaryId={beneficiaryID}
                programId={actionProgramID}
                open={openDialogProgram}
                setOpenFn={setOpenDialogProgram}
                actionCallback={(value) => addBeneficiaries(id, value)}
              />
            )}
            <DialogBeneficiaryNotes
              beneficiaryId={beneficiaryID}
              cwpid={cwpID}
              open={openDialog}
              setOpenFn={setOpenDialog}
              fetchNotesAfterSave={false}
            />
            <DialogEditDate
              dialogBeneficiary={beneficiaryData}
              open={openDialogEditDate}
              setOpenFn={setOpenDialogEditDate}
              typeAction
            />
            {currentItem && openDialogConfirmEnd && (
              <DialogConfirm
                item={currentItem}
                removeFunction={() =>
                  endBeneficiaryParticipationInAction(action.id, currentItem.cwa_id)
                }
                title={`Potwierdź zakończenie udziału beneficjenta ${currentItem.name} ${currentItem.surname} w działaniu "${action.name}".`}
                open={openDialogConfirmEnd}
                setOpenFn={setOpenDialogConfirmEnd}
              />
            )}
            {currentItem && openDialogConfirmRemove && (
              <DialogConfirm
                item={currentItem}
                removeFunction={() => removeBeneficiaryFromAction(action.id, currentItem.cwa_id)}
                title={`Potwierdź usunięcie beneficjenta ${currentItem.name} ${currentItem.surname} z działania "${action.name}", jego dane w działaniu zostaną utracone!`}
                open={openDialogConfirmRemove}
                setOpenFn={setOpenDialogConfirmRemove}
              />
            )}
          </>
        )}
    </>
  );
};

ActionBeneficiaries.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    build_in_type: PropTypes.string,
    start_date: PropTypes.string,
    program: PropTypes.shape({
      id: PropTypes.number,
      wasAnonymized: PropTypes.bool,
    }),
  }),
  clearActionMembers: PropTypes.func,
  fetchMembers: PropTypes.func,
  members: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
  clearBeneficiaries: PropTypes.func,
  fetchBeneficiaryList: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  addBeneficiaries: PropTypes.func,
  removeBeneficiaryFromAction: PropTypes.func,
  endBeneficiaryParticipationInAction: PropTypes.func,
  membersLoaded: PropTypes.bool,
  showAlert: PropTypes.func,
  beneficiariesLoaded: PropTypes.bool,
  me: PropTypes.shape({
    user_programs: PropTypes.arrayOf(PropTypes.any),
    user_role: PropTypes.arrayOf(PropTypes.any),
    program_roles: PropTypes.arrayOf(PropTypes.any),
  }),
};

ActionBeneficiaries.defaultProps = {
  action: null,
  clearActionMembers: null,
  fetchMembers: null,
  members: [],
  clearBeneficiaries: null,
  fetchBeneficiaryList: null,
  beneficiaries: [],
  addBeneficiaries: null,
  removeBeneficiaryFromAction: null,
  endBeneficiaryParticipationInAction: null,
  membersLoaded: false,
  showAlert: null,
  beneficiariesLoaded: false,
  me: null,
};

const mapStateToProps = ({ actionReducer, beneficiaryReducer, userReducer }) => ({
  action: actionReducer.action,
  members: actionReducer.members ? actionReducer.members.beneficiaries : [],
  membersLoaded: actionReducer.membersLoaded ? actionReducer.membersLoaded : false,
  beneficiaries: beneficiaryReducer.beneficiaries,
  beneficiariesLoaded: beneficiaryReducer.beneficiariesLoaded,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearActionMembers: () => dispatch(clearActionMembersAction()),
  fetchMembers: (id) => dispatch(fetchActionMembersService(id)),
  clearBeneficiaries: () => dispatch(clearBeneficiariesAction()),
  fetchBeneficiaryList: (program_id) => dispatch(fetchBeneficiaryListService(program_id)),
  addBeneficiaries: (id, data) => dispatch(addBeneficiariesService(id, data)),
  removeBeneficiaryFromAction: (action_id, cwa_id) =>
    dispatch(removeBeneficiaryFromActionService(action_id, cwa_id)),
  endBeneficiaryParticipationInAction: (action_id, cwa_id) =>
    dispatch(endBeneficiaryParticipationInActionService(action_id, cwa_id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionBeneficiaries);
